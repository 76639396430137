<template>
  <div>
    <app-sidebar />

    <app-navbar />

    <div class="app--main-content">
      <v-container class="app--content-container" fluid>
        <router-view />
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    'app-navbar': () => import('@/components/TheNavbar.vue'),
    'app-sidebar': () => import('@/components/TheSidebar.vue'),
  }
}
</script>